import { string } from 'prop-types';
import styles from './IconEnvironment.module.scss';

const IconEnvironment = ({ color = '#004D69' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           width='86'
           height='86'
           viewBox="0 0 44.4 38.2"
      >

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className={styles.cls1}
                  d="M44.4,1.4a7.7,7.7,0,0,1-.2,1.5c-.1,1.5-.3,3.1-.4,4.6s-.3,3-.5,4.4-.1.8-.1,1.1-.1.2-.2.2l-2.9-.3c-.1,0-.2,0-.2-.2s.3-2.2.4-3.3l.3-2.7h-.2l-9.8,8.1c-.2.2-.3.2-.5,0L21.7,8.1h-.4L10.8,16.4,2.4,23.1c-.2.1-.2.1-.4-.1l-.9-1.1c0-.1-.1-.1-.1-.2s.1-.2.2-.3L7,16.8l6.6-5.2L19.4,7l1.8-1.4c.3-.2.3-.2.6,0l5.5,4.5,2.8,2.2c.2.2.3.1.5,0L35,8.6l4.5-3.7c.6-.5,1.3-.9,1.8-1.4l2.7-2C44.1,1.4,44.2,1.3,44.4,1.4Z"/>
            <path className={styles.cls2} d="M44.4,1.4h-.3L41.9,3.1l-.8.7c-1.4,1-2.7,2.2-4,3.2l-5.7,4.8-.9.7h-.4L21.7,5.7a.3.3,0,0,0-.4,0l-8.9,7-10,7.9L1,21.7.1,20.5c-.2-.2-.1-.2.1-.4l2.2-1.7,9.3-7.5L21,3.5c.2-.1.2-.1.4,0l8.4,6.8h.4L38,3.9l.2-.2h-.9l-1.4-.2-1.8-.2-2-.2h-.7c0-.5.1-1,.1-1.5A4.9,4.9,0,0,0,31.6.2c.1-.2.1-.2.3-.2l9.3,1,3,.3Z"/>
            <path className={styles.cls2} d="M33.3,25.8V14.4c0-.4,0-.4.3-.4h7a.3.3,0,0,1,.3.3V37.2a.3.3,0,0,1-.3.3h-7c-.3,0-.3,0-.3-.3Z"/>
            <path className={styles.cls2} d="M31.6,19.3c.1,0,.1.1.1.2V37.2a.3.3,0,0,1-.3.3H24.5c-.5,0-.4.1-.4-.4V19.6c0-.1-.1-.3.1-.3h3.7c.1,0,.1.1.1.2V36.6c0,.7.1.7.7.7H31c.5,0,.5,0,.5-.5V19.5C31.5,19.4,31.5,19.3,31.6,19.3Z"/>
            <path className={styles.cls1} d="M31.6,19.3V37.1c0,.3,0,.3-.3.3h-3c-.4,0-.4,0-.4-.3V19.3h3.7Z"/>
            <path className={styles.cls2} d="M22.5,37.4H15.1c-.2,0-.2,0-.2-.2h0V23.6c0-.1-.1-.3.1-.3h4a.4.4,0,0,1,.1.3V36.7c0,.6,0,.6.7.6h2.7Z"/>
            <path className={styles.cls2} d="M7.5,37.8H6.8c-.2.1-.2,0-.2-.2V35.4c0-.2,0-.2-.2-.3a4.4,4.4,0,0,1-3.8-3.3,6.4,6.4,0,0,1,.3-3.6,16.2,16.2,0,0,1,2.5-3.9,20,20,0,0,1,1.9-2.2c.1-.1.1-.2.2-.1a.7.7,0,0,1,.1.5V37.4C7.6,37.5,7.7,37.7,7.5,37.8Z"/>
            <path className={styles.cls1} d="M22.5,37.4H19.3c-.3,0-.3,0-.3-.3V23.3h3.3c.2,0,.2,0,.2.2h0V37.4Z"/>
            <path className={styles.cls1} d="M7.5,37.8V22a18,18,0,0,1,4.6,6.4,5.3,5.3,0,0,1,.3,3.5,4.6,4.6,0,0,1-2.7,2.9,1.6,1.6,0,0,1-.9.3.3.3,0,0,0-.3.3v2.2c0,.2,0,.3-.2.2Z"/>
            <path className={styles.cls2} d="M23.1,41.2H4.7c-.4,0-.4,0-.4-.3a8.6,8.6,0,0,0,0-1.6c0-.2.1-.2.3-.2H23.1a.4.4,0,0,1,.1.3v1.5A.4.4,0,0,1,23.1,41.2Z"/>
            <path className={styles.cls2} d="M23.1,44.7H4.7c-.4,0-.4,0-.4-.3V42.8l.2-.2H23.1c.1,0,.1.1.1.2v1.5A.4.4,0,0,1,23.1,44.7Z"/>
            <path className={styles.cls1} d="M23.1,41.2V39.1H40.4c.2,0,.3,0,.3.3V41c0,.1,0,.2-.2.2Z"/>
            <path className={styles.cls1} d="M23.1,44.7v-2c0-.1.1-.1.2-.1H40.4c.3,0,.3.1.3.3v1.6c0,.1,0,.2-.3.2H23.1Z"/>
            <path className={styles.cls3} d="M19,23.3Z"/>
            <path className={styles.cls4} d="M31.4,2.9l2,.2h.7L31.5,3A.1.1,0,0,1,31.4,2.9Z"/>
            <path className={styles.cls5} d="M27.9,19.3Z"/>
            <path className={styles.cls6} d="M36,3.4l2,.2s.2,0,.2.1l-2.1-.2H36Z"/>
            <path className={styles.cls7} d="M36,3.4H34.1Z"/>
            <path className={styles.cls1} d="M40.8,25.8V37.1c0,.3,0,.3-.3.3H37.4c-.2,0-.3,0-.3-.3V14.4c0-.3.1-.3.3-.3h3.1c.3,0,.3,0,.3.3Z"/>
          </g>
        </g>
      </svg>
  );
};

IconEnvironment.propTypes = {
  color: string,
};

IconEnvironment.defaultProps = {
  color: '#004D69',
};

export default IconEnvironment;
