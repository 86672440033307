import cx from 'classnames';
import { useRouter } from 'next/router';
import { string } from 'prop-types';
import React from 'react';

import { InternalExternalLink } from '@/components/Link/InternalExternalLink';

import { SiteContext } from '../../context';
import { getBreadCrumbForPath } from '../../helpers/sitemap/getBreadCrumbForPath';

import styles from './BreadCrumbs.module.scss';

export default function BreadCrumbs({ location, color }) {
  const onHeader = location === 'byHeader';

  const defaultColor = onHeader ? '#8f4e6d' : '#ffffff';

  const textColor = { style: { color: `${color || defaultColor} ` } };

  const { sitemap, preview } = React.useContext(SiteContext);
  const router = useRouter();
  const path = getBreadCrumbForPath(router?.asPath.split('?')[0], sitemap);

  if (!path?.length && !preview) return null;
  if (path.length === 1) return null;

  return (
    <nav aria-label="breadcrumbs">
      <ol
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        className={cx(styles.list, { [styles.onHeader]: onHeader })}
      >
        {!path?.length && preview && (
          <>
            <li
              className={onHeader ? styles.listNews__item : styles.list__item}
              {...textColor}
            >
              path
              <span className={styles.separator}>&rsaquo;</span>
            </li>
            <li
              className={onHeader ? styles.listNews__item : styles.list__item}
              {...textColor}
            >
              to
              <span className={styles.separator}>&rsaquo;</span>
            </li>
            <li
              className={onHeader ? styles.listNews__item : styles.list__item}
              {...textColor}
            >
              page
            </li>
          </>
        )}
        {path?.map((item, index) => (
          <li
            key={item.path}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            className={onHeader ? styles.listNews__item : styles.list__item}
            {...textColor}
          >
            {index === path.length - 1 ? (
              <span className={styles.lastItem}>
                <span itemProp="name">{item.title}</span>
                <meta itemProp="position" content={`${index + 1}`} />
              </span>
            ) : (
              <>
                <InternalExternalLink
                  href={item.path}
                  itemProp="item"
                  aria-current={index === path.length - 1 ? 'location' : null}
                  className={styles.alink}
                >
                  <span itemProp="name">{item.title}</span>
                </InternalExternalLink>
                <meta itemProp="position" content={`${index + 1}`} />
                {index < path.length - 1 && (
                  <span className={styles.separator}>&rsaquo;</span>
                )}
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

BreadCrumbs.propTypes = {
  location: string,
  color: string,
};

BreadCrumbs.defaultProps = {
  location: '',
  color: '',
};
