import { string, shape, bool } from 'prop-types';
import React from 'react';

import Corporate from '../../Corporate/index';

import styles from './HomePage.module.scss';

export default function HomePage({ data }) {
  const {
    paragraphFirst,
    paragraphSecond,
    background,
    colorText,
    isCorporate,
    signpost,
  } = data;
  return (
    <section
      style={{ backgroundColor: background.value }}
      className={styles.container}
    >
      <div className={styles.wrapText}>
        <p style={{ color: colorText?.value }} className={styles.container__title}>
          {paragraphFirst}
        </p>
        {paragraphSecond && (
          <p
            style={{ paddingTop: 0, color: colorText?.value }}
            className={styles.container__title}
          >
            {paragraphSecond}
          </p>
        )}
      </div>
      {isCorporate && <Corporate size={'desktop'} signpost={signpost} />}
    </section>
  );
}

HomePage.propTypes = {
  paragraphFirst: string,
  paragraphSecond: string,
  isCorporate: bool,
  colorText: shape({
    value: string,
    title: string,
  }),
  background: shape({
    value: string,
    title: string,
  }),
};

HomePage.defaultProps = {
  paragraphFirst: '',
  paragraphSecond: '',
  background: {},
  colorText: {},
  isCorporate: true,
};
