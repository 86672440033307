import { string } from 'prop-types';


import styles from './IconPeople.module.scss';

const IconPeople = ({ color = '#004D69' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           width='86'
           height='86'
           viewBox="0 0 44.4 38.2"
      >

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className={styles.cls1} d="M17.9,0A17.8,17.8,0,0,1,33.7,10a.8.8,0,0,1,.2-.5A5.4,5.4,0,0,1,38,5.9a5.5,5.5,0,0,1,6.3,4,5.9,5.9,0,0,1-.9,5.1l-2.8,3.3c-1.3,1.4-2.6,2.9-4,4.3A13.8,13.8,0,0,1,34,24.8l-.2.3a18.6,18.6,0,0,1-3.6,5.2,17,17,0,0,1-8.8,4.8,17.3,17.3,0,0,1-3.2.3h-.4a.3.3,0,0,1-.3-.3V31.3c0-.1,0-.1.1-.2s.2-.1.3-.1l2.9-.4a13.3,13.3,0,0,0,5-2.6,12.4,12.4,0,0,0,2.7-2.9A15.9,15.9,0,0,0,30,22.2c.1-.3.1-.5-.1-.6l-2.2-2L26,17.9a22.5,22.5,0,0,1-2.1-2.4,10.6,10.6,0,0,1-1.2-2,4,4,0,0,1-.3-1.5,5,5,0,0,1,.4-2.7,5.5,5.5,0,0,1,1.8-2.4q.3-.2,0-.3A12.3,12.3,0,0,0,20,4.8l-1.7-.2h-.6c-.1,0-.1-.1-.1-.2a.4.4,0,0,1-.1-.3V.3C17.6,0,17.6,0,17.9,0Z"/>
            <path className={styles.cls2} d="M17.9,0a.3.3,0,0,0-.3.3V4.2c0,.2.1.3.3.2h-.5a11.5,11.5,0,0,0-2.6.3,9.4,9.4,0,0,0-2.8,1A13.8,13.8,0,0,0,9.2,7.8a15.5,15.5,0,0,0-2.4,2.7,13.2,13.2,0,0,0-1.9,4.6A14.3,14.3,0,0,0,4.6,18a14,14,0,0,0,1.1,4.9,12,12,0,0,0,2.1,3.4,13.9,13.9,0,0,0,3.7,3.1,12.3,12.3,0,0,0,4.2,1.4l1.6.2c.2,0,.3-.1.3.1v4.1c0,.2.1.3.3.2H15.8l-3.3-.7a16.3,16.3,0,0,1-6.4-3.6,17.1,17.1,0,0,1-5.6-9,18.7,18.7,0,0,1-.4-2.5v-.2A4.1,4.1,0,0,1,0,18.3V17.2a3.7,3.7,0,0,1,.1-1.1v-.2a16.8,16.8,0,0,1,1.3-5A17.5,17.5,0,0,1,6,4.4,18.2,18.2,0,0,1,13.7.5L16.3.1h1.6Z"/>
            <path className={styles.cls3} d="M0,17.2a2,2,0,0,1,0,1.1Z"/>
            <path className={styles.cls4} d="M.1,15.9a.1.1,0,0,1,0,.2Z"/>
            <path className={styles.cls5} d="M.1,19.4c.1.1,0,.1,0,.2Z"/>
            <path className={styles.cls1} d="M17.7,18.3a5.9,5.9,0,0,1,3.2.6,4.5,4.5,0,0,1,2.2,3,11,11,0,0,1,.2,1.8V27a.4.4,0,0,1-.1.3,11.2,11.2,0,0,1-4.9,1.5h-.4c-.2,0-.3,0-.3-.3V18.8A.7.7,0,0,1,17.7,18.3Z"/>
            <path className={styles.cls2} d="M17.7,18.3V28.6c0,.1.1.2.3.2h-.7a11.1,11.1,0,0,1-4.8-1.3c-.2-.1-.2-.2-.2-.4V23.5a5.8,5.8,0,0,1,.5-2.6,4.5,4.5,0,0,1,3.3-2.5h1.6Z"/>
            <path className={styles.cls2} d="M13.8,13.6a3.5,3.5,0,0,1,2.5-3.1l1.4-.2H18a1.3,1.3,0,0,0-.1.6v6.3a.9.9,0,0,1-.1.5c-.1.1-.3,0-.5,0a3.1,3.1,0,0,1-2.4-1l-.2-.2a1.9,1.9,0,0,1-.7-1.1c0-.2-.1-.3-.1-.4h0V13.6Z"/>
            <path className={styles.cls1} d="M17.8,17.7V10.6c0-.1-.1-.3.2-.3a3.8,3.8,0,0,1,3.1,1.6,3.5,3.5,0,0,1,.6,2.6,3.4,3.4,0,0,1-1.1,2.3,4,4,0,0,1-2.1.9c-.2,0-.5.1-.7,0Z"/>
            <path className={styles.cls6} d="M13.8,13.6a2.7,2.7,0,0,1,0,.9h0Z"/>
            <path className={styles.cls7} d="M13.8,14.5h0v.4A.6.6,0,0,1,13.8,14.5Z"/>
            <path className={styles.cls8} d="M13.9,15c.1.1.1.2.1.4A.8.8,0,0,1,13.9,15Z"/>
            <path className={styles.cls9} d="M17.8,17.7Z"/>
            <path className={styles.cls10} d="M14.7,16.5c.1,0,.1.1.2.2A.2.2,0,0,1,14.7,16.5Z"/>
          </g>
        </g>
      </svg>
  );
};

IconPeople.propTypes = {
  color: string,
};

IconPeople.defaultProps = {
  color: '#004D69',
};

export default IconPeople;
