import classnames from 'classnames';
import { string, arrayOf, shape, bool, exact } from 'prop-types';
import React from 'react';

import IconCommunities from '@/components/assets/IconCommunities/IconCommunities';
import IconEnvironment from '@/components/assets/IconEnvironment/IconEnvironment';
import IconInvestPeople from '@/components/assets/IconInvestPeople/IconInvestPeople';
import IconPeople from '@/components/assets/IconPeople/IconPeople';
import IconSafe from '@/components/assets/IconSafe/IconSafe';
import IconSustainable from '@/components/assets/IconSustainable/IconSustainable';

import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs';
import Corporate from '../../Corporate/index';

import styles from './SecondaryPage.module.scss';

export default function SecondaryPage({ data }) {
  const {
    paragraphFirst,
    paragraphSecond,
    background,
    isIcon,
    icon,
    breadCrumbs,
    breadcrumbsColor,
    colorText,
    isCorporate,
    signpost,
  } = data;

  const containerClasses = classnames(styles.container, {
    [styles.containerCorporate]: isCorporate,
  });

  const icons = {
    environment: <IconEnvironment />,
    communities: <IconCommunities />,
    'invest-people': <IconInvestPeople />,
    people: <IconPeople />,
    safe: <IconSafe />,
    sustainable: <IconSustainable />,
  };

  return (
    <div style={{ backgroundColor: background?.value }} className={containerClasses}>
      <div className={styles.content}>
        {breadCrumbs !== false && (
          <div className={styles.wrap}>
            <BreadCrumbs color={breadcrumbsColor?.value} />
          </div>
        )}

        <div className={isIcon ? styles.contentWithIcon : styles.withOutIcon}>
          {isIcon && icons[icon]}

          <p style={{ color: colorText?.value }} className={styles.titleFirst}>
            {paragraphFirst}
          </p>
        </div>

        {paragraphSecond && (
          <p style={{ color: colorText?.value }} className={styles.titleSecond}>
            {paragraphSecond}
          </p>
        )}
      </div>
      {isCorporate && <Corporate size={'desktop'} signpost={signpost} />}
    </div>
  );
}

SecondaryPage.propTypes = {
  isIcon: bool,
  paragraphFirst: string,
  isCorporate: bool,
  paragraphSecond: string,
  background: shape({
    value: string,
    title: string,
  }),
  colorText: shape({
    value: string,
    title: string,
  }),
  breadCrumbs: arrayOf(
    exact({
      name: string,
      url: string,
    }),
  ),
  breadcrumbsColor: shape({
    value: string,
  }),
};

SecondaryPage.defaultProps = {
  isIcon: false,
  paragraphFirst: '',
  variant: '',
  background: {},
  colorText: {},
  paragraphSecond: '',
  breadCrumbs: [],
  isCorporate: true,
  breadcrumbsColor: {
    value: '#ffffff',
  },
};
