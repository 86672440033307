import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import IconArrow, {
  COLOUR as VARIANT,
} from '@/components/assets/IconArrow/IconArrow';
import { IconHelpToBuy } from '@/components/assets/IconBenefits/IconBenefits';
import { sanityClient } from '@/lib/sanity';

import { InternalExternalLink } from '../../Link/InternalExternalLink';

import stylesMobile from './Corporate.module.scss';
import styleXxl from './CorporateXXL.module.scss';

const corporateQuery = `
  *[_type == 'siteConfig'][0].contacts.corporate
`;
const consumerQuery = `
  *[_type == 'corporateSiteConfig'][0].contacts.consumer
`;

export const COLOUR = {
  black: '#004D69',
  ORANGE: '#F5841F',
  NAVY: '#072241',
  black_ORIGIN: '#ffffff',
};

const iconVariant = (icon, theme) => {
  let iconColor;

  switch (theme) {
    case 'navy':
      iconColor = COLOUR.ORANGE;
      break;
    case 'pink':
      iconColor = COLOUR.WHITE_ORIGIN;
      break;
    case 'pastel':
      iconColor = COLOUR.NAVY;
      break;
    default:
      iconColor = COLOUR.ORANGE;
  }

  return {
    logo: (
      <svg
        className={stylesMobile.logoSize}
        width="26"
        height="32"
        viewBox="0 0 26 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5503 0L0.0853333 12.5755L0 12.6619L0.000627451 19.343L12.5465 32L18.0675 26.4302V19.7498L12.2566 13.889V21.1553L14.1747 23.0906L12.5465 24.7337L3.89208 16.0022L12.5515 7.26631L21.2053 15.9978L20.2805 16.9307L20.2786 24.1982L25.098 19.3368V12.6582L12.5503 0ZM12.8402 6.72774V1.12005L24.3959 12.7789L21.6163 15.583L12.8402 6.72774ZM0.700235 12.7838L12.2585 1.12378V6.73458L3.48047 15.5886L0.700235 12.7838ZM24.5176 13.486V19.0949L20.8621 22.7822V17.1726L21.8855 16.1396V16.1409L24.5176 13.486ZM0.582274 19.1005L0.581647 13.4909L3.21569 16.1483L12.2585 25.2704V30.8787L0.582274 19.1005ZM12.8376 20.9127L12.837 15.3038L17.3653 19.8717L14.5857 22.6771L12.8376 20.9127ZM12.8402 25.266L14.8304 23.2592L17.4871 20.5794L17.4864 26.1871L12.8402 30.875V25.266Z"
          fill={iconColor}
        />
      </svg>
    ),
    houseInHands: <IconHelpToBuy color={iconColor} />,
  }[icon];
};

export default function Corporate({ size, signpost = {} }) {
  const {
    signpostType = 'corporate',
    signpostIcon = 'logo',
    signPostBackground = { title: 'navy', value: '#072241' },
    signpostTitle,
    signpostText,
  } = signpost;
  const [data, setData] = useState(null);
  const query = signpostType === 'corporate' ? corporateQuery : consumerQuery;

  const getData = async () =>
    sanityClient.fetch(query).then((result) => setData(result));

  useEffect(() => {
    getData();
  }, []);

  const styles = size === 'desktop' ? styleXxl : stylesMobile;

  const classes = classnames(
    styles.container,
    styles[signPostBackground.title],
    'group relative',
  );

  const title =
    signpostType === 'corporate' ? 'Glenveagh Corporate' : 'Glenveagh Customer';

  return (
    <div className={classes} style={{ backgroundColor: signPostBackground?.value }}>
      <div className={styles.wrapLogo}>
        {iconVariant(signpostIcon, signPostBackground.title)}
        <div
          className={classnames(
            styles.container__title,
            'group-hover:underline group-focus-within:underline underline-offset-4',
          )}
        >
          {!data?.url ? (
            <span>{signpostTitle ?? title}</span>
          ) : (
            <InternalExternalLink link={data.url}>
              <span className="relative z-10">{signpostTitle ?? title}</span>
              <span className="absolute inset-0 z-10 bg-transparent block" />
            </InternalExternalLink>
          )}
        </div>
      </div>

      {data && (
        <div className={styles.wrapLink}>
          <p>{signpostText ?? data.title}</p>
          <div>
            <IconArrow
              size="large"
              color={
                signPostBackground.title === 'pastel'
                  ? VARIANT.LIGHT_BLUE
                  : VARIANT.WHITE
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
