import { string } from 'prop-types';

import styles from './IconSafe.module.scss';

const IconSafe = ({ color = '#004D69' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           width='86'
           height='86'
           viewBox="0 0 44.4 38.2"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className={styles.cls1} d="M43.5,19.7a2.4,2.4,0,0,0-3-.7,16.1,16.1,0,0,0-5.9,4.4,4.4,4.4,0,0,1-1.5,1.2,8,8,0,0,1-2.1.5c-.2,0-.1-.1-.1-.2a3.5,3.5,0,0,0-3.3-3H24.9a3.2,3.2,0,0,1-2.4-.9,6.6,6.6,0,0,0-4.1-1.7c-1-.1-2,0-3.1,0a8.4,8.4,0,0,0-4.6,1.3L7.2,22.9H7l-.6-1.4c-.2-.4-.2-.4-.6-.2a25.5,25.5,0,0,1,1.3,2.9l2,4.5c0,.2-.1.2-.2.2l-3,1.3L3.5,31.2c-.1,0-.2,0-.2.1h0l3,7c.1.2.2.2.3.1s.2,0,.3.1l.3.7c0,.2.1.2.2.1l1.1-.5,4.4-1.9c.2-.1.2-.2.1-.3a3.6,3.6,0,0,1-.4-.9l-.3-.7h.2l4.5.4,3.4.3a11.5,11.5,0,0,0,4.9-.4l10.1-3.2a8.4,8.4,0,0,0,4.3-3.1l4.1-6.3A2.7,2.7,0,0,0,43.5,19.7Zm-1.2,2.4-3.7,5.7a6.7,6.7,0,0,1-3.7,2.9L24.8,33.9a9.1,9.1,0,0,1-4,.3l-4.9-.4-4.2-.4c-.1,0-.2-.1-.2-.3L8.6,26.5a14.2,14.2,0,0,0-.8-1.8c-.1-.2-.1-.3.1-.3L11.5,22a7.7,7.7,0,0,1,3.9-1.1h2.3a5.6,5.6,0,0,1,3.9,1.5,4.9,4.9,0,0,0,3.1,1.1h2.7a1.9,1.9,0,0,1,1.9,1.8,2,2,0,0,1-1.9,2H19.2c-.1,0-.2,0-.2.2v1.2c0,.1,0,.2.2.2h8.3a3.4,3.4,0,0,0,3-1.9c.1-.1.1-.2.3-.2l1.9-.4a5.8,5.8,0,0,0,3.1-1.9,14.5,14.5,0,0,1,5.2-4,1,1,0,0,1,1.3,1.6Z"/>
            <path className={styles.cls1} d="M25.5.4V18.8h3.6c.1,0,.2-.1.2-.3V13.4c0-.3-.1-.3.3-.3H35c.2,0,.2,0,.2-.2v-7c0-.2,0-.2-.2-.2H29.5c-.2,0-.2,0-.2-.2V0H25.8C25.5,0,25.5,0,25.5.4Z"/>
            <path className={styles.cls2} d="M16.4,13.1h4.7c.5,0,.5,0,.5.5v4.9c0,.2,0,.3.2.3h3.7V.3c0-.2,0-.3.2-.3H21.9c-.3,0-.3.1-.3.3V5.4a.3.3,0,0,1-.3.3H16.4c-.2,0-.3.1-.3.2.1,2.3.1,4.7,0,7C16.1,13.1,16.2,13.1,16.4,13.1Z"/>
            <path className={styles.cls2} d="M28.6,0h-3c-.2,0-.2.1-.2.3V18.8h0V.4c0-.4,0-.4.3-.4h2.8Z"/>
            <path className={styles.cls2} d="M3.5,31l5.2-2.2c.3-.1.3-.1.2-.4S7.8,26,7.3,24.8L5.9,21.5c-.1-.1-.2-.2-.1-.3s-.3.1-.5.1-.2.2-.3.2l-.5.2c0,.1-.1.1-.2.1H3.9c0,.1-.1.1-.2.1H3.2c-.1.2-.1.2-.2.2H2.6l-.4.2H1.4c0,.1-.1.1-.2.1H1.1c0,.1-.1,0-.2.1H.4c0,.1-.1.1-.2,0h0c-.2.1-.2.2-.2.4.9,1.8,1.6,3.6,2.4,5.5l.9,1.9C3.3,31.1,3.4,31.1,3.5,31Z"/>
            <path className={styles.cls2} d="M5.9,30.1l3-1.3c.1,0,.2,0,.2-.2l-2-4.5a25.5,25.5,0,0,0-1.3-2.9c-.1.1,0,.2.1.3l1.4,3.3c.5,1.2,1,2.4,1.6,3.6s.1.3-.2.4L3.5,31c-.1.1-.2.1-.2.3s.1-.1.2-.1Z"/>
            <path className={styles.cls3} d="M5,21.5l.3-.2Z"/>
            <path className={styles.cls3} d="M5,21.5H5c.1,0,.2,0,.3-.2Z"/>
            <path className={styles.cls4} d="M4.3,21.8h0Z"/>
            <path className={styles.cls4} d="M4.5,21.7H4.3C4.4,21.8,4.5,21.8,4.5,21.7Z"/>
            <path className={styles.cls5} d="M3.7,22c.1,0,.2,0,.2-.1Z"/>
            <path className={styles.cls5} d="M3.7,22c.1,0,.2,0,.2-.1S3.8,22,3.7,22Z"/>
            <path className={styles.cls6} d="M3.3,22.2Z"/>
            <path className={styles.cls6} d="M3.3,22.2h0Z"/>
            <path className={styles.cls7} d="M3,22.4l.2-.2Z"/>
            <path className={styles.cls7} d="M3,22.4c.1,0,.1,0,.2-.2Z"/>
            <path className={styles.cls8} d="M2.6,22.5h0Z"/>
            <path className={styles.cls8} d="M2.6,22.5h0Z"/>
            <path className={styles.cls9} d="M1.9,22.8h0Z"/>
            <path className={styles.cls9} d="M2.2,22.7h0Z"/>
            <path className={styles.cls10} d="M1.6,22.9h0Z"/>
            <path className={styles.cls10} d="M1.6,22.9h0Z"/>
            <path className={styles.cls11} d="M1.2,23.1c.1,0,.2,0,.2-.1Z"/>
            <path className={styles.cls11} d="M1.2,23.1c.1,0,.2,0,.2-.1S1.3,23.1,1.2,23.1Z"/>
            <path className={styles.cls12} d="M.6,23.4h0Z"/>
            <path className={styles.cls12} d="M.6,23.4h0Z"/>
            <path className={styles.cls13} d="M.2,23.5a.1.1,0,0,0,.2,0Z"/>
            <path className={styles.cls13} d="M.4,23.5a.1.1,0,0,1-.2,0C.3,23.6.4,23.6.4,23.5Z"/>
            <path className={styles.cls14} d="M4,21.9Z"/>
            <path className={styles.cls14} d="M4.2,21.8h0Z"/>
            <path className={styles.cls15} d="M.9,23.3h.2C1,23.2.9,23.2.9,23.3Z"/>
            <path className={styles.cls15} d="M1.1,23.2H.9C1,23.2,1.1,23.3,1.1,23.2Z"/>
          </g>
        </g>
      </svg>
  );
};

IconSafe.propTypes = {
  color: string,
};

IconSafe.defaultProps = {
  color: '#004D69',
};

export default IconSafe;
