import { string } from 'prop-types';


import styles from './IconSustainable.module.scss';

const IconSustainable = ({ color = '#004D69' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           width='86'
           height='86'
           viewBox="0 0 44.4 38.2"
      >

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className={styles.cls1} d="M38,15.7h0Z"/>
            <path className={styles.cls2} d="M22.2,10.6a7.4,7.4,0,1,0,7.4,7.5A7.5,7.5,0,0,0,22.2,10.6ZM19.7,22c0,.3.1.6.1,1a5.7,5.7,0,0,1-3-6.6l.3.6a3.5,3.5,0,0,0,1.1,1.5l.4.2a.8.8,0,0,1,.5.7c0,.3-.1.6-.1.9a.4.4,0,0,0,.2.4l.6.5a.2.2,0,0,1,0,.3C19.6,21.6,19.6,21.8,19.7,22Zm5.9-8.1a.8.8,0,0,1-.8.2c-.1-.1-.2,0-.2.2v.2a.4.4,0,0,0,.2.4,1,1,0,0,1,.2.7c.1.1,0,.1-.1.2l-.8.5c-.1.1-.2.2-.2.3a2.8,2.8,0,0,1-.1.9c0,.1,0,.2.2.3a1.1,1.1,0,0,1,.5.9c0,.2,0,.4.2.5s.5,1,.7,1.5,0,.2-.1.2l-.6.6c-.1.1-.1.1,0,.2s.3.5.4.8.1.1,0,.2a5.9,5.9,0,0,1-3.9.7h0l.4-.3c.1-.1.2-.2.1-.4s0-.3.2-.3h0a1.2,1.2,0,0,0,.8-.9l.2-.4a.5.5,0,0,0-.1-.6h0c-.7-.1-1-.6-1.3-1.1s-.2-.3-.4-.3l-1.2-.4a.2.2,0,0,1-.2-.2c.1-.2-.1-.4-.3-.5l-.4-.3a.4.4,0,0,1-.1-.3c0-.1,0-.3.1-.3s.6-.2.8,0,.4.2.6,0,0-.1-.1-.2,0-.2.1-.3l1-.7c.1-.1.2-.1.1-.3s.1-.3.3-.4l.3-.4c.2-.1.2-.1.1-.3l-.5-.6c-.1-.1-.1-.1,0-.2l.3-.5c.1-.1.2-.3.1-.4s-.1-.3.2-.3a5.3,5.3,0,0,1,3.4,1.3C25.7,13.7,25.7,13.8,25.6,13.9Z"/>
            <path className={styles.cls3} d="M16.8,10V2.3h-.6L8.3.9C7.9.9,7.4.7,7,.9s-.2.3-.3.4L5.8,2.5a.6.6,0,0,0,.2.9L8.3,4.5l2.3,1.1,2.3,1.2c.1,0,.2,0,.2.1v.2a20.1,20.1,0,0,0-2.5,1.4l-4,2.3-.6.3H5.7L2.4,9.9h-1c0,.2-.2.2-.2.3l-.8,1a.6.6,0,0,0,.1.9l3.3,2.5a2.3,2.3,0,0,0,1.8.6h.2l1.1-.4,1.5-.8,8.2-3.9Z"/>
            <path className={styles.cls2} d="M16.9,5.5v4a.7.7,0,0,1-.1.5L20,8.5l7.3-3.6,2-1a2.5,2.5,0,0,0,1.1-1.2A1.4,1.4,0,0,0,30.1.9L29.5.5A5.4,5.4,0,0,0,25,.5L20.2,2.7h-.6l-2.8-.5a.6.6,0,0,1,.1.4Z"/>
            <path className={styles.cls2} d="M16.8,3v7a.7.7,0,0,0,.1-.5V2.7a.6.6,0,0,0-.1-.4Z"/>
            <path className={styles.cls2} d="M24.5,34.8c-.2,0-.2-.1-.2-.2V32.9c0-.4-.1-.6-.5-.7l-1.3-.3-.3-.2-1.6-2.9A1.7,1.7,0,0,0,19,28a5.6,5.6,0,0,0-1.3,0l-.3-.3a2,2,0,0,0-1.9-1.2H10.2a.9.9,0,0,0-1,1,1.3,1.3,0,0,1,.5-.6h5.5c.2,0,.3,0,.3.3v9.2c0,.4,0,.4.3.4h2.7c.4,0,.4,0,.4-.3v-.2h0a2.4,2.4,0,0,1,2.3-1.9,2.2,2.2,0,0,1,2.1,2.4c0,.1.1.1.2.1h.9c.2,0,.4-.1.4-.3V35.1A.3.3,0,0,0,24.5,34.8Zm-3.9-3H18.2c-.2,0-.2,0-.2-.2v-2c0-.1,0-.2.2-.2h1.1l.3.2,1.1,2C20.8,31.8,20.8,31.8,20.6,31.8Z"/>
            <path className={styles.cls3} d="M18.7,36.7H15.6c-.2,0-.2,0-.2-.2V27.4c0-.5-.1-.5-.6-.5H10.4a1,1,0,0,0-1,.5H9.2v8.6c.1.4.4.8.7.8h.6c.1,0,.2,0,.2-.2a2.2,2.2,0,0,1,2.1-2.3,2.3,2.3,0,0,1,2.3,2.3c0,.1,0,.2.1.2s.2.1.3-.1.1-.2.2-.2h2.9c.3,0,.3,0,.3-.3v.1C18.9,36.7,18.9,36.7,18.7,36.7Z"/>
            <path className={styles.cls3} d="M18.9,36.4c0,.3,0,.3-.4.3H15.8c-.3,0-.3,0-.3-.4V27.1c0-.3-.1-.3-.3-.3H9.7a1.3,1.3,0,0,0-.5.6h.2a1,1,0,0,1,1-.5h4.4c.5,0,.6,0,.6.5v9.1c0,.2,0,.2.2.2h3.1c.2,0,.2,0,.2-.2v-.3h0Z"/>
            <path className={styles.cls3} d="M39.3,25.5V21.3c0-.1-.1-.2,0-.3h-.4a.1.1,0,0,1-.1.1l-.7.2-2,.8a28.5,28.5,0,0,0-3,1.6c-.3.2-.3.3-.1.6a5.3,5.3,0,0,1,1.1,2.1,1.4,1.4,0,0,0,1,1h.2a1.5,1.5,0,0,0,.7-.3V27a1.3,1.3,0,0,0,1,.6,2.4,2.4,0,0,0,2-.4h.1A9.7,9.7,0,0,0,39.3,25.5Zm-1.2-1a.4.4,0,0,1-.5.2c-.3-.1-.4-.3-.5-.7a.8.8,0,0,1,.5-.8c.2-.1.3,0,.4.1A1.1,1.1,0,0,1,38.1,24.5Z"/>
            <path className={styles.cls2} d="M45.3,23.7A24.4,24.4,0,0,0,41,21.6a4.9,4.9,0,0,0-1.7-.6h0a.6.6,0,0,1,.1.4v5.3c0,.2,0,.4-.2.5l.5.3a2.6,2.6,0,0,0,1.5.1c.5-.1.9-.2,1.1-.6s.6.5,1.1.3h.1a1,1,0,0,0,.7-.7,5.1,5.1,0,0,1,1.2-2.3C45.6,24,45.6,23.9,45.3,23.7Zm-4.3.9a.4.4,0,0,1-.6,0,1.1,1.1,0,0,1,0-1.3.4.4,0,0,1,.6,0,.7.7,0,0,1,.2.6A.8.8,0,0,1,41,24.6Z"/>
            <path className={styles.cls2} d="M39.4,26.7V21.4a.6.6,0,0,0-.1-.4c-.1.1,0,.2,0,.3v4.2a9.7,9.7,0,0,1-.1,1.7C39.4,27.1,39.4,26.9,39.4,26.7Z"/>
            <path className={styles.cls2} d="M6.2,20.5A21.1,21.1,0,0,1,6.4,17c.1-.1,0-.2-.1-.2H5.6c-.2-.1-.2,0-.2.1a12.4,12.4,0,0,0-.3,2.7,19.7,19.7,0,0,0,.4,3.4A13.2,13.2,0,0,0,7,26.8c.1.2.1.2.3.1l.5-.3c.2-.1.2-.1.1-.3A14.4,14.4,0,0,1,6.2,20.5Z"/>
            <path className={styles.cls2} d="M35.3,29a1,1,0,0,0-.5-.4c-.1-.2-.2-.2-.4,0a13.2,13.2,0,0,1-5.1,4,9.4,9.4,0,0,1-2.8,1c-.1,0-.2,0-.1.1a2.9,2.9,0,0,0,.2.9.1.1,0,0,0,.1.1h.1A15.2,15.2,0,0,0,31,33a17.7,17.7,0,0,0,4.4-3.8C35.4,29.1,35.5,29,35.3,29Z"/>
            <path className={styles.cls2} d="M33,4.2a13.8,13.8,0,0,1,3.9,4.4,13.6,13.6,0,0,1,1.4,3.3c0,.1,0,.2.2.2l.7-.2c.2,0,.2-.1.1-.3a12.8,12.8,0,0,0-1.4-3.4,14.5,14.5,0,0,0-4.3-4.9h-.2l-.4.5C32.9,4,32.9,4,33,4.2Z"/>
            <path className={styles.cls3} d="M38.5,13.5h-.1a.8.8,0,0,0-.2.5v1.3c0,.2,0,.3-.2.3h-.2a.2.2,0,0,1-.3,0h-.1l-.8.2a.6.6,0,0,0-.5.6L35.8,18c-.1.2-.1.3-.3.4s-.6.3-.6.7l-.6,2.8a.3.3,0,0,1-.1.2h.2l.5-.3c.2,0,.2-.1.3-.3s.3-1.4.4-2.1.1-.3.3-.4a.9.9,0,0,0,.6-.6,5,5,0,0,0,.2-1.2.7.7,0,0,1,.6-.6,9.7,9.7,0,0,1,2.4-.2c-.4-.1-.4-.1-.4-.6v-2c0-.3,0-.3.3-.3H38.5Z"/>
            <path className={styles.cls2} d="M39.4,14v1.8c0,.2,0,.4.2.5h.1a4.5,4.5,0,0,1,1.6.3.3.3,0,0,1,.3.3l.3,1.3c0,.4.2.7.6.7s.2.2.3.3.3,1.5.4,2.2a.4.4,0,0,0,.1.3l.8.3h0c-.2-1.1-.4-2.2-.7-3.3s-.1-.2-.2-.3h-.3l-.3-.3-.3-1.6a.8.8,0,0,0-.5-.5l-1.5-.3c-.1,0-.1-.1-.1-.2V14c0-.3-.2-.4-.5-.5h0C39.4,13.6,39.4,13.8,39.4,14Z"/>
            <path className={styles.cls2} d="M39.3,13.8v2c0,.5,0,.5.4.6h-.1c-.2-.1-.2-.3-.2-.5V14c0-.2,0-.4.3-.5h-.1C39.3,13.5,39.3,13.5,39.3,13.8Z"/>
            <path className={styles.cls2} d="M21.1,34.8a1.7,1.7,0,0,0-1.7,1.7,1.8,1.8,0,0,0,1.7,1.7,1.7,1.7,0,0,0,1.7-1.7A1.6,1.6,0,0,0,21.1,34.8Zm0,2.4a.7.7,0,0,1-.7-.7.7.7,0,1,1,1.4,0A.7.7,0,0,1,21.1,37.2Z"/>
            <path className={styles.cls3} d="M13,35a1.9,1.9,0,0,0-1.7,1c0,.1,0,.2-.1.2h0v.7a1.7,1.7,0,0,0,2.4,1.4,1.8,1.8,0,0,0,1-1.8.4.4,0,0,1-.1-.3A1.8,1.8,0,0,0,13,35Zm.3,2.3a.6.6,0,0,1-.8,0,.4.4,0,0,1-.3-.6.7.7,0,0,1,.7-.7.8.8,0,0,1,.7.7h0A.7.7,0,0,1,13.3,37.3Z"/>
            <path className={styles.cls4} d="M12.9,7c.1-.1,0-.1-.1-.2l-2.1-1L6.1,3.5c-.2-.1-.5-.2-.5-.5s0-.3.1-.5l1-1.3L7,.9a.8.8,0,0,0-.4.4l-1,1.2c-.4.4-.2.8.2,1L12.9,7Z"/>
            <path className={styles.cls4} d="M5.7,2.5c-.1.2-.2.3-.1.5s.3.4.5.5l4.6,2.3,2.1,1c.1.1.2.1.1.2H13V6.9c0-.1-.1-.1-.2-.1L10.6,5.6,8.3,4.5,6,3.4a.6.6,0,0,1-.2-.9l.9-1.2c.1-.1.3-.2.3-.4l-.3.3Z"/>
            <path className={styles.cls5} d="M4.9,15.2a4.1,4.1,0,0,1-1.5-.7L.5,12.2a.7.7,0,0,1-.1-1.1l1-1.2c-.2.1-.3.1-.4.3L.1,11.3a.7.7,0,0,0,.1.9l3.5,2.6a2.7,2.7,0,0,0,2.1.5H4.9Z"/>
            <path className={styles.cls5} d="M5.6,15.2a2.3,2.3,0,0,1-1.8-.6L.5,12.1a.6.6,0,0,1-.1-.9l.8-1c0-.1.2-.1.2-.3l-1,1.2a.7.7,0,0,0,.1,1.1l2.9,2.3a4.1,4.1,0,0,0,1.5.7h.7Z"/>
            <path className={styles.cls6} d="M34.1,26.6A7,7,0,0,0,33,24.3c-.3-.3-.3-.4.1-.7l3.3-1.7,1.6-.6h0l-1.8.8a15.9,15.9,0,0,0-3.4,1.8c-.2.1-.2.2-.1.4A7,7,0,0,1,34,26.6a1.3,1.3,0,0,0,1.3.9l-.6-.2A1.1,1.1,0,0,1,34.1,26.6Z"/>
            <path className={styles.cls6} d="M34.1,26.4A5.3,5.3,0,0,0,33,24.3c-.2-.3-.2-.4.1-.6a28.5,28.5,0,0,1,3-1.6l2-.8H38l-1.6.6-3.3,1.7c-.4.3-.4.4-.1.7a7,7,0,0,1,1.1,2.3,1.1,1.1,0,0,0,.6.7l.6.2h-.2A1.4,1.4,0,0,1,34.1,26.4Z"/>
            <path className={styles.cls5} d="M34.2,21.8l.6-2.9a.8.8,0,0,1,.5-.5.8.8,0,0,0,.4-.4,6.9,6.9,0,0,0,.2-1.3.9.9,0,0,1,.6-.7h0c-.4,0-.6.3-.7.7s-.2.9-.2,1.3l-.4.4a.6.6,0,0,0-.5.6c-.1.2-.1.5-.2.7a20.5,20.5,0,0,1-.5,2.4h.2Z"/>
            <path className={styles.cls5} d="M34.9,19.1c0-.4.2-.7.6-.7s.2-.2.3-.4l.3-1.4a.6.6,0,0,1,.5-.6h-.1a.9.9,0,0,0-.6.7,6.9,6.9,0,0,1-.2,1.3.8.8,0,0,1-.4.4.8.8,0,0,0-.5.5l-.6,2.9v.3a.3.3,0,0,0,.1-.2Z"/>
            <path className={styles.cls3} d="M39.6,17.2h-2c-.1.2,0,.5-.1.7h.3l1.5-.2v-.3C39.3,17.3,39.3,17.3,39.6,17.2Z"/>
            <path className={styles.cls7} d="M40.8,20.2l1.4.4c0-.2-.1-.5-.1-.7h-.2l-1-.3h-.2c-.1.2,0,.4,0,.6Z"/>
            <path className={styles.cls7} d="M40.9,18a.6.6,0,0,1-.1-.4c0-.2-.1-.2-.2-.2l-1-.2h0c-.2.2-.3.4-.2.6h1.3C40.9,18.2,40.9,18.1,40.9,18Z"/>
            <path className={styles.cls7} d="M39.6,17.2c-.3.1-.3.1-.3.4v.3h.1C39.3,17.6,39.4,17.4,39.6,17.2Z"/>
            <path className={styles.cls3} d="M36.3,20.6a3.2,3.2,0,0,1,.9-.3c.5-.1.5-.1.5-.7h0a.1.1,0,0,0-.1-.1h-.4c0,.1-.1.1-.2.1h-.4a.8.8,0,0,0-.4.4.7.7,0,0,1-.1.5h.2Z"/>
            <path className={styles.cls5} d="M11.2,36.2h0Z"/>
            <path className={styles.cls5} d="M14.5,36.1h0Z"/>
            <path className={styles.cls5} d="M13.7,35.1a1.6,1.6,0,0,1,.8,1h0a1.6,1.6,0,0,0-1.7-1.3,1.6,1.6,0,0,0-1.6,1.3h0A1.8,1.8,0,0,1,13.7,35.1Z"/>
            <path className={styles.cls5} d="M13.7,35.1a1.8,1.8,0,0,0-2.5,1.1h0c.1,0,.1-.1.1-.2A1.9,1.9,0,0,1,13,35a1.8,1.8,0,0,1,1.5,1.2h0A1.6,1.6,0,0,0,13.7,35.1Z"/>
            <path className={styles.cls3} d="M38.2,20.2h1.3s-.2,0-.2-.1a.5.5,0,0,1,0-.5.1.1,0,0,1,.1-.1H38.2v.5c0,.2,0,.2-.2.2h.2Z"/>
            <path className={styles.cls8} d="M39.6,19.5c-.2.1-.3.3-.2.4s.2.1.1.2h.3c.4.1.4.1.4-.3s0-.4-.4-.4h-.2Z"/>
            <path className={styles.cls8} d="M39.3,19.5a.5.5,0,0,0,0,.5c0,.1.2.1.2.1s0-.1-.1-.2,0-.3.2-.4h-.2A.1.1,0,0,0,39.3,19.5Z"/>
            <path className={styles.cls9} d="M11.2,8a9.5,9.5,0,0,0,1.7-1h-.2l-3.5,2-3.3,2L7,10.4Z"/>
            <path className={styles.cls9} d="M10.5,8.5A20.1,20.1,0,0,1,13,7.1h-.1a9.5,9.5,0,0,1-1.7,1L7,10.4l-1.1.7.6-.3Z"/>
            <path className={styles.cls6} d="M38,15.6c.1,0,.1-.1.1-.2V14.2a.7.7,0,0,1,.4-.7.5.5,0,0,0-.5.5v1.6h0Z"/>
            <path className={styles.cls6} d="M38.1,14.2v1.2c0,.1,0,.2-.1.2h0c.2,0,.2-.1.2-.3V14.1a.8.8,0,0,1,.2-.5h.1A.7.7,0,0,0,38.1,14.2Z"/>
            <path className={styles.cls10} d="M36.4,19.7h.4l-.6.2h-.1a1.3,1.3,0,0,0-.1.6c-.1.2,0,.1.1.1h0a1.5,1.5,0,0,1,.1-.7Z"/>
            <path className={styles.cls10} d="M36.6,19.7h-.2l-.2.2a1.5,1.5,0,0,0-.1.7.7.7,0,0,0,.1-.5A.8.8,0,0,1,36.6,19.7Z"/>
            <path className={styles.cls11} d="M37.6,17.4h0c-.2,0-.2,0-.2.2a.6.6,0,0,0-.1.4c-.1.2,0,.2.2.1A.6.6,0,0,1,37.6,17.4Z"/>
            <path className={styles.cls11} d="M37.6,17.4h0a.6.6,0,0,0-.1.7c.1-.2,0-.5.1-.7Z"/>
            <path className={styles.cls12} d="M38.2,19.4c-.1,0-.2,0-.2.2v.5h0c.1-.1.1-.2.1-.4A.4.4,0,0,1,38.2,19.4Z"/>
            <path className={styles.cls12} d="M38.2,19.9v-.5a.4.4,0,0,0-.1.3c0,.2,0,.3-.1.4S38.2,20.1,38.2,19.9Z"/>
            <path className={styles.cls13} d="M36,27.2a1.5,1.5,0,0,0,1.1.4,1.8,1.8,0,0,1-1-.4Z"/>
            <path className={styles.cls13} d="M36.1,27v.2h.1a1.8,1.8,0,0,0,1,.4A1.3,1.3,0,0,1,36.1,27Z"/>
            <path className={styles.cls14} d="M38.1,21.2l.8-.2h0l-.9.2Z"/>
            <path className={styles.cls14} d="M38.1,21.3l.7-.2a.1.1,0,0,0,.1-.1l-.8.2Z"/>
            <path className={styles.cls14} d="M38.1,21.2Z"/>
            <path className={styles.cls14} d="M38.1,21.2h0Z"/>
            <path className={styles.cls15} d="M36.6,16l.8-.2h0l-.9.2Z"/>
            <path className={styles.cls15} d="M36.6,16l.8-.2-.8.2Z"/>
            <path className={styles.cls15} d="M36.6,16h0Z"/>
            <path className={styles.cls15} d="M36.5,16h0Z"/>
            <path className={styles.cls16} d="M37.6,17.4h0Z"/>
            <path className={styles.cls16} d="M37.6,17.4h0Z"/>
            <path className={styles.cls16} d="M37.6,17.4Z"/>
            <path className={styles.cls16} d="M37.6,17.4Z"/>
            <path className={styles.cls17} d="M14.5,36.2c.1.1,0,.2.1.3a.4.4,0,0,0-.1-.3Z"/>
            <path className={styles.cls17} d="M14.5,36.2a.4.4,0,0,0,.1.3c-.1-.1,0-.2-.1-.3Z"/>
            <path className={styles.cls17} d="M14.5,36.2Z"/>
            <path className={styles.cls17} d="M14.5,36.1h0Z"/>
            <path className={styles.cls18} d="M11.2,36.2v0Z"/>
            <path className={styles.cls18} d="M11.2,36.2h0v0Z"/>
            <path className={styles.cls18} d="M11.2,36.2Z"/>
            <path className={styles.cls18} d="M11.2,36.2Z"/>
            <path className={styles.cls19} d="M37.2,19.5c-.1,0-.2,0-.2.1h.2Z"/>
            <path className={styles.cls19} d="M37,19.6c.1,0,.2,0,.2-.1Z"/>
            <path className={styles.cls3} d="M39.5,17.9h0Z"/>
            <path className={styles.cls3} d="M39.5,17.9h0Z"/>
            <path className={styles.cls15} d="M37.8,15.7h-.3A.2.2,0,0,0,37.8,15.7Z"/>
            <path className={styles.cls15} d="M37.8,15.7h0a.2.2,0,0,1-.3,0A.2.2,0,0,0,37.8,15.7Z"/>
            <path className={styles.cls20} d="M37,19.6Z"/>
            <path className={styles.cls20} d="M37,19.6h0Z"/>
            <path className={styles.cls20} d="M36.8,19.6Z"/>
            <path className={styles.cls20} d="M36.8,19.7h0Z"/>
            <path className={styles.cls21} d="M38.5,17.2h0Z"/>
            <path className={styles.cls21} d="M38.5,17.2h0Z"/>
            <path className={styles.cls22} d="M38,15.7h0Z"/>
            <path className={styles.cls22} d="M37.8,15.7Z"/>
            <path className={styles.cls22} d="M37.8,15.7Z"/>
            <path className={styles.cls22} d="M37.8,15.7Z"/>
            <path className={styles.cls6} d="M37.6,23.2c.2,0,.4.1.4.3a.9.9,0,0,1,0,1l-.4.2a.4.4,0,0,0,.5-.2,1.1,1.1,0,0,0-.1-1.2C37.9,23.2,37.8,23.1,37.6,23.2Z"/>
            <path className={styles.cls6} d="M38,24.5a.9.9,0,0,0,0-1c0-.2-.2-.3-.4-.3a.8.8,0,0,1,0,1.5Z"/>
            <path className={styles.cls6} d="M12.9,37.3c-.4,0-.7-.2-.7-.6a.4.4,0,0,0,.3.6.6.6,0,0,0,.8,0,.7.7,0,0,0,.3-.6A.7.7,0,0,1,12.9,37.3Z"/>
            <path className={styles.cls6} d="M12.9,37.3a.7.7,0,0,0,.7-.6h0c-.2.3-.4.5-.7.5a.7.7,0,0,1-.7-.5C12.2,37.1,12.5,37.3,12.9,37.3Z"/>
          </g>
        </g>
      </svg>
  );
};

IconSustainable.propTypes = {
  color: string,
};

IconSustainable.defaultProps = {
  color: '#004D69',
};

export default IconSustainable;
