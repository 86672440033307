import { string } from 'prop-types';

import styles from './IconInvestPeople.module.scss';

const IconInvestPeople = ({ color = '#004D69' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           width='86'
           height='86'
           viewBox="0 0 44.4 38.2"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className={styles.cls1} d="M39,20.1a11.1,11.1,0,0,1-.2,1.7c-.1,1.5-.3,3-.4,4.5s-.3,2.6-.5,4,0,.2-.2.1l-2.5-.2c-.1,0-.1-.1-.1-.2s.2-2.4.4-3.6.1-1.1.1-1.7l-.5.4-8.2,6.8c-.2.2-.2.2-.5,0L19.1,26c-.2-.1-.3-.1-.4,0L3.1,38.3l-1,.8a.2.2,0,0,1-.3,0L.9,37.9c0-.1.1-.2.2-.3L6,33.8l11-8.7,1.6-1.3c.3-.2.3-.2.6,0l7.1,5.8h.2a.2.2,0,0,0,.3,0l5-4.1,3.7-3.1,3.2-2.3C38.8,20.1,38.9,20,39,20.1Z"/>
            <path className={styles.cls2} d="M39,20.1c-.3,0-.5.3-.7.4l-1.4,1.1-1,.7-3.2,2.6-3.1,2.6-2.8,2.3h-.3l-1.7-1.3-3.2-2.7a24.3,24.3,0,0,1-2.5-2c-.2-.1-.3-.1-.4,0l-3.4,2.7-6.2,5L4.3,35.2.9,37.9.1,36.8c-.1-.1-.1-.2,0-.2l6.3-5.1,8.8-7,3.3-2.6h.3l7.4,6c.2.1.2.1.4,0l6.8-5.6c0-.1.1-.1.1-.2s-.1,0-.2,0h-.4c-.2,0-.3-.1-.4,0H32a.1.1,0,0,1-.2,0H30.5c0-.1.1-.1.1,0a.2.2,0,0,0-.3,0c-.1,0-.2,0-.2-.1H27.9c0-.1-.1,0-.2,0s-.2,0-.1-.1a21.1,21.1,0,0,1,.2-2.5h2l4.2.5,4.6.5C38.7,20,38.9,19.9,39,20.1Z"/>
            <path className={styles.cls2} d="M4.7,25a.2.2,0,0,1-.2-.2,5.7,5.7,0,0,0,0-1.3c0-1.8,0-3.6.1-5.4a4.6,4.6,0,0,1,.8-2.8,4.4,4.4,0,0,1,2.5-1.5,6.8,6.8,0,0,1,2-.2H9.8c-.2,0-.2.1-.2.3V24.6a.6.6,0,0,1-.1.4H4.7Z"/>
            <path className={styles.cls1} d="M9.5,25V14c0-.3,0-.3.4-.4H10a5.3,5.3,0,0,1,3.2,1.2,4.3,4.3,0,0,1,1.3,2.6v4.9a.4.4,0,0,1-.1.3l-2.9,2.3c-.1.1-.2.2-.4.1Z"/>
            <path className={styles.cls1} d="M21.9,13.6a4.8,4.8,0,0,1,3.6,1.6,4.4,4.4,0,0,1,.9,2.7v6.9c0,.1.1.2,0,.2s-.2,0-.3,0l-1.7-1.4-2.9-2.3a.1.1,0,0,0-.1-.1.6.6,0,0,1-.1-.4V14.1C21.3,13.6,21.4,13.6,21.9,13.6Z"/>
            <path className={styles.cls2} d="M21.9,13.6c-.5.1-.5.1-.5.6v7L19,19.3c-.2-.2-.2-.2-.4,0l-2,1.5h-.1V18.1a4.3,4.3,0,0,1,1.2-3.2,4.6,4.6,0,0,1,2.4-1.2h1.8Z"/>
            <path className={styles.cls1} d="M9.5,6.4a4.1,4.1,0,0,1,2.1.5A3.1,3.1,0,0,1,13.2,10a3.2,3.2,0,0,1-3.3,3.1c-.5.1-.5.1-.5-.5V6.8A.6.6,0,0,1,9.5,6.4Z"/>
            <path className={styles.cls2} d="M21.8,13.1a3.4,3.4,0,0,1-4-2.8,3.3,3.3,0,0,1,1-3,3.5,3.5,0,0,1,2.6-.9h0a.6.6,0,0,1,.1.4v5.9c0,.2.1.3.2.3Z"/>
            <path className={styles.cls1} d="M21.8,13.1c-.4,0-.4,0-.4-.5V6.4a4.5,4.5,0,0,1,2,.4,3.2,3.2,0,0,1,1.7,3.3,3.3,3.3,0,0,1-2.7,3Z"/>
            <path className={styles.cls2} d="M9.5,6.4v6.3c0,.4,0,.4.3.4h.1c-1.9.2-4.2-.8-4-3.6A3.1,3.1,0,0,1,9,6.4C9.2,6.4,9.3,6.3,9.5,6.4Z"/>
            <path className={styles.cls1} d="M15.5,0a3.9,3.9,0,0,1,2.7,1,3.4,3.4,0,0,1,.9,2.4,3.1,3.1,0,0,1-3.2,3.3c-.5.1-.5.1-.5-.4V.4C15.4.3,15.3.1,15.5,0Z"/>
            <path className={styles.cls2} d="M15.5,0V6.3c0,.4,0,.4.4.4a3.3,3.3,0,0,1-4-3A3.3,3.3,0,0,1,12.8.9,4.3,4.3,0,0,1,15,0Z"/>
            <path className={styles.cls3} d="M9.5,25Z"/>
            <path className={styles.cls4} d="M31.2,21.9h0Z"/>
            <path className={styles.cls5} d="M32.5,22a.3.3,0,0,1,.4,0h-.4Z"/>
            <path className={styles.cls6} d="M28.1,21.5h0Z"/>
            <path className={styles.cls7} d="M28.6,21.6h.3a.1.1,0,0,1-.1.1Z"/>
            <path className={styles.cls8} d="M29.4,21.7h0Z"/>
            <path className={styles.cls9} d="M30.3,21.8h.3a.1.1,0,0,1-.1.1Z"/>
            <path className={styles.cls10} d="M30.7,21.8c.1-.1.3,0,.4,0h-.4Z"/>
            <path className={styles.cls11} d="M31.6,21.9Z"/>
            <path className={styles.cls7} d="M32,21.9h0Z"/>
            <path className={styles.cls12} d="M32.9,22Z"/>
            <path className={styles.cls13} d="M27.7,21.5c.1,0,.2-.1.2,0Z"/>
            <path className={styles.cls14} d="M29.9,21.7h0Z"/>
            <path className={styles.cls15} d="M33.3,22.1c.1-.1.2-.1.2,0Z"/>
          </g>
        </g>
      </svg>
  );
};

IconInvestPeople.propTypes = {
  color: string,
};

IconInvestPeople.defaultProps = {
  color: '#004D69',
};

export default IconInvestPeople;
