import { oneOf } from 'prop-types';
import React from 'react';

import HomePage from './components/HomePage';
import SecondaryPage from './components/SecondaryPage';

export default function Overview(props) {
  const { variant } = props;
  const isHomePage = variant === 'home';
  return (
    <>
      {props && (
        <div>
          {isHomePage && <HomePage data={props} />}
          {!isHomePage && <SecondaryPage data={props} />}
        </div>
      )}
    </>
  );
}

Overview.propTypes = {
  variant: oneOf(['home', 'secondary']),
};

Overview.defaultProps = {
  variant: 'secondary',
};
