import { string } from 'prop-types';

const IconCommunities = ({ color = '#004D69' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           width='86'
           height='86'
        viewBox="0 0 44.4 38.2"
        >
        <defs>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className="cls-1"
                  fill={"#d5d6d8"}
                  d="M31.8,21.9H28.7c-.5,0-.5,0-.5-.5V13.9c0-.2-.1-.3-.2-.5l-3.8-3.3h-.3l-4.3,3.4a.5.5,0,0,0-.2.4v7.6c0,.4,0,.4-.3.4h-3a.3.3,0,0,1-.3-.3V12.3a.7.7,0,0,1,.3-.6l4.5-3.6L24,5.3h.3L30,10.4h.2l.4.3,1.2,1.1c.1,0,.1.1.1.2v9.3A1.1,1.1,0,0,1,31.8,21.9Z"/>
            <path className="cls-1"
                  fill={"#d5d6d8"}

                  d="M24.1,38.1H3c-.4,0-.4,0-.4-.4s.1-3,0-4.5.1-.2.3-.2H24.1a.4.4,0,0,1,.1.3v4.5C24.2,38,24.2,38.1,24.1,38.1Z"/>
            <path className="cls-2"
                  fill={"#fefefe"}
                  d="M24.1,38.1v-5h20c.3,0,.3-.1.3.3v4.5a.3.3,0,0,1-.3.3h-20Z"/>
            <path className="cls-1"
                  fill={"#d5d6d8"}
                  d="M7,21.9H5.9c-.2,0-.2-.2-.2-.3V18.5c0-.3,0-.3-.3-.4a6.2,6.2,0,0,1-3.8-2A5.1,5.1,0,0,1,.1,13,9,9,0,0,1,1,7.9,19.7,19.7,0,0,1,4.2,3,35.8,35.8,0,0,1,6.8.1H7a1.7,1.7,0,0,1,.1.7V21.2A1.7,1.7,0,0,1,7,21.9Z"/>
            <path className="cls-2"
                  fill={"#fefefe"}
                  d="M7,21.9V.6C7,.4,6.9.2,7,0a27.1,27.1,0,0,1,6,8.2,7.5,7.5,0,0,1,.9,3.9,6,6,0,0,1-3.3,5.4,7.6,7.6,0,0,1-2,.6c-.2,0-.2.1-.2.3v3.2a.3.3,0,0,1-.3.3Z"/>
            <path className="cls-2"
                  fill={"#fefefe"}
                  d="M31.8,21.9V12.3c0-.2-.1-.3-.2-.5l-1.2-1.1-.2-.2,6.4-5.2h.3l5.5,4.9,1.8,1.7.2.3v9.4a.3.3,0,0,1-.3.3H41a.3.3,0,0,1-.3-.3c.1-2.6,0-5.1.1-7.7,0-.2-.1-.3-.3-.5l-3.7-3.3h-.4l-4.2,3.4a.5.5,0,0,0-.2.4v7.7C32,21.8,32,21.9,31.8,21.9Z"/>
            <path className="cls-1"
                  fill={"#d5d6d8"}
                  d="M24.1,31.3H2.9c-.3,0-.3,0-.3-.3a19.2,19.2,0,0,0,0-2.4c0-.2.1-.2.3-.2h21c.1,0,.1-.1.2,0a.4.4,0,0,1,.1.3V31C24.2,31.1,24.2,31.3,24.1,31.3Z"/>
            <path className="cls-1"
                  fill={"#d5d6d8"}
                  d="M2.7,26.4a23.5,23.5,0,0,0-.1-2.6c0-.2.1-.2.3-.2H24.1a.4.4,0,0,1,.1.3v2.2c0,.2,0,.3-.1.3H2.9C2.8,26.5,2.7,26.5,2.7,26.4Z"/>
            <path className="cls-2"
                  fill={"#fefefe"}
                  d="M24.1,26.4V23.6h20c.3,0,.3,0,.3.3v2.5H24.1Z"/>
            <path className="cls-2"
                  fill={"#fefefe"}
                  d="M24.1,31.3V28.5h20c.3,0,.3,0,.3.3v2.3c0,.2-.1.2-.3.2h-20Z"/>
            <path className="cls-3"
                  fill={"#d9ddd9"}
                  d="M24.1,26.4H2.9c-.1,0-.2,0-.2-.1Z"/>
          </g>
        </g>
      </svg>
  );
};

IconCommunities.propTypes = {
  color: string,
};

IconCommunities.defaultProps = {
  color: '#004D69',
};

export default IconCommunities;
